/* montserrat-300 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local('Montserrat Light'), local('Montserrat-Light'),
    url('../src/assets/fonts/montserrat-v14-latin-300.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url('../src/assets/fonts/montserrat-v14-latin-300.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-regular - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Montserrat Regular'), local('Montserrat-Regular'),
    url('../src/assets/fonts/montserrat-v14-latin-regular.woff2')
      format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url('../src/assets/fonts/montserrat-v14-latin-regular.woff')
      format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  -webkit-overflow-scrolling: touch;
}

body {
  margin: 0 auto;
  height: 100%;
  overflow: hidden;
  position: relative;
}

html {
  font-size: 10px;
  background-color: white;
  height: 100%;
  overflow: hidden;

  @media screen and (min-width: 1200px) {
    font-size: 12px;
  }
}

.appWrapper {
  height: 100%;
  overflow: hidden;
}

.scene {
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  perspective: 1200px;
}

.page {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: white;
  backface-visibility: hidden;
  overflow-y: scroll;
  transform: translate3d(0, 0, 0);
  transform-style: preserve-3d;
}

.show {
  display: block !important;
}

.hide {
  display: none !important;
}

.hideOverflow {
  overflow: hidden;
}

.rotateCubeTopOut {
  transform-origin: 50% 100%;
  animation: rotateCubeTopOut 1.5s both ease-in;
}

.rotateCubeTopIn {
  transform-origin: 50% 0%;
  animation: rotateCubeTopIn 1.5s both ease-in;
}

.rotateCubeBottomOut {
  transform-origin: 50% 0%;
  animation: rotateCubeBottomOut 1.5s both ease-in;
}

.rotateCubeBottomIn {
  transform-origin: 50% 100%;
  animation: rotateCubeBottomIn 1.5s both ease-in;
}

@keyframes rotateCubeTopOut {
  50% {
    animation-timing-function: ease-out;
    transform: translateY(-50%) translateZ(-200px) rotateX(45deg);
  }
  100% {
    opacity: 0.3;
    transform: translateY(-100%) rotateX(90deg);
  }
}

@keyframes rotateCubeTopIn {
  0% {
    opacity: 0.3;
    transform: translateY(100%) rotateX(-90deg);
  }
  50% {
    animation-timing-function: ease-out;
    transform: translateY(50%) translateZ(-200px) rotateX(-45deg);
  }
}

@keyframes rotateCubeBottomOut {
  50% {
    animation-timing-function: ease-out;
    transform: translateY(50%) translateZ(-200px) rotateX(-45deg);
  }
  100% {
    opacity: 0.3;
    transform: translateY(100%) rotateX(-90deg);
  }
}

@keyframes rotateCubeBottomIn {
  0% {
    opacity: 0.3;
    transform: translateY(-100%) rotateX(90deg);
  }
  50% {
    animation-timing-function: ease-out;
    transform: translateY(-50%) translateZ(-200px) rotateX(45deg);
  }
}
