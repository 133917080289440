.hero {
  height: calc(var(--vh, 1vh) * 100);
  width: 100vw;
  padding: 20vh 2rem 10vh 2rem;
  color: white;
  background-color: black;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  .header {
    font-size: 5rem;
    text-align: center;

    @media screen and (min-width: 375px) {
      font-size: 5.5rem;
    }

    @media screen and (min-width: 500px) {
      max-width: 80%;
    }

    @media screen and (min-width: 600px) {
      font-size: 8rem;
      max-width: 512px;
    }

    @media screen and (max-height: 420px) {
      font-size: 4rem;
    }
  }
}
