.container {
  width: 100%;
  -webkit-transform: translate3d(0, 0, 0);
}

.myProjects {
  background-color: #000000;
  color: white;

  .headerWrapper {
    margin: 0 auto;
    display: flex;
    align-items: center;

    @media screen and (min-width: 800px) {
      max-width: 90%;
    }

    @media screen and (min-width: 1400px) {
      max-width: 1200px;
    }
  }

  .header {
    font-size: 3.4rem;
    padding: 3rem 2rem;
    margin-right: 1rem;

    @media screen and (min-width: 800px) {
      padding: 3rem 0;
    }

    @media screen and (min-width: 600px) {
      margin-right: 2rem;
    }
  }
}
