.menu {
  position: fixed;
  top: 0;
  left: 100vw;
  width: 100vw;
  height: 100vh;
  padding: 20vh 20vw;
  background: rgb(255, 255, 255);
  list-style-type: none;
  -webkit-font-smoothing: antialiased;
  transition: all 0.5s ease-in-out;
  z-index: 500;

  @media screen and (max-height: 450px) {
    padding: 10vh 20vw;
  }

  &.show {
    left: 0;
    li,
    hr,
    .iconSection {
      opacity: 1;
      transform: translateX(0);
    }
  }

  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  li {
    font-size: 3.5rem;
    display: flex;

    @media screen and (max-height: 450px) {
      font-size: 3rem;
    }
  }

  li,
  hr,
  .iconSection {
    opacity: 0;
    transform: translateX(-9rem);
    transition: all 0.9s ease, color 0.2s;

    @media screen and (min-width: 800px) {
      transform: translateX(-20rem);
    }
  }

  hr {
    width: 100%;

    @media screen and (min-width: 800px) {
      width: 50%;
    }
  }

  button,
  a {
    text-decoration: none;
    color: #232323;
    width: fit-content;
    background: none;
    border: none;

    &:hover {
      color: tomato;
      cursor: pointer;
    }
  }

  .iconSection {
    width: 75%;
    display: flex;
    justify-content: space-around;

    @media screen and (min-width: 800px) {
      width: 50%;
    }
  }
}
