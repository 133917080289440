.githubIconContainer {
  display: flex;
  align-items: center;

  .githubIcon {
    width: 2.5rem;
    height: 2.5rem;
    transition: all 0.2s ease;
  }

  &:hover {
    cursor: pointer;
    fill: tomato;
  }
}
