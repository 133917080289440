.container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.about {
  color: black;
  padding: 3rem 2rem;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  background-color: white;

  @media screen and (min-width: 800px) {
    max-width: 90%;
    height: 40vw;
    min-height: 40rem;
    max-height: 800px;
    padding: 0;

    .wrapper {
      display: grid;
      grid-template-columns: 60% 40%;
    }
  }

  @media screen and (min-width: 1400px) {
    max-width: 1200px;
  }

  .what {
    font-size: 1.4rem;
    line-height: 1.7rem;
    letter-spacing: 0.1rem;
    text-transform: uppercase;
    color: #c4c4c4;
    padding: 3rem 0;
  }

  .welcome {
    font-size: 3.1rem;
    line-height: 3.6rem;
    padding-bottom: 2.5rem;

    @media screen and (min-width: 800px) {
      grid-column: 1 / 1;
      grid-row: 1 / 1;
      margin-right: 2rem;
    }
  }

  .intro {
    font-size: 2rem;
    font-weight: 300;
    line-height: 2.4rem;
    padding-bottom: 2.5rem;
    line-height: 2.6rem;

    @media screen and (min-width: 800px) {
      grid-column: 1 / 1;
      grid-row: 2 / 2;
      margin-right: 2rem;
    }
  }

  .skills {
    @media screen and (min-width: 800px) {
      grid-column: 2 / 2;
      grid-row: 1 / span 2;
      align-self: center;
      justify-self: center;
    }

    ol {
      columns: 2;
      counter-reset: my-awesome-counter;
      list-style: none;
      padding-bottom: 3rem;

      @media screen and (min-width: 800px) {
        columns: 1;
      }

      li {
        counter-increment: my-awesome-counter;
        font-size: 1.5rem;
        padding: 1rem 2.5rem;
        letter-spacing: 0.1rem;
        position: relative;

        @media screen and (max-width: 800px) {
          &:nth-child(4) {
            padding-top: 0;

            &::before {
              content: '0' counter(my-awesome-counter);
              position: absolute;
              top: -0.5rem;
              left: 0;
              color: #686868;
              font-weight: bold;
            }
          }
        }

        &::before {
          content: '0' counter(my-awesome-counter);
          position: absolute;
          top: 0.5rem;
          left: 0;
          color: #686868;
          font-weight: bold;
          font-size: 1.1rem;
        }
      }
    }
  }
}
