.resumeIconContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  justify-items: flex-start;

  .resumeIcon {
    width: 2.5rem;
    height: 2.5rem;
    transition: all 0.2s ease;
  }

  &:hover {
    cursor: pointer;
    fill: tomato;
  }
}
