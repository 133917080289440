.arrowContainerHide {
  display: none;
}

.arrowContainerShow {
  display: unset;
}

.arrow {
  width: 6.4rem;

  @media screen and (max-height: 400px) {
    max-width: 5rem;
  }
}
