.container {
  position: relative;
}

.tooltip {
  position: absolute;
  z-index: 1000;
  transform: translate(-50%, 5%);
  transition: opacity 0.3s ease-in-out;
  opacity: 1;

  width: 280px;
  background-color: rgb(27, 27, 27);
  padding: 1.5rem;
  border-radius: 3px;

  @media screen and (max-width: 450px) {
    transform: translate(-90%, 5%);
  }

  animation: fadeInFromNone 0.3s ease-in-out;

  p {
    font-size: 1.6rem;
    font-weight: 300;
  }
}

.hide {
  opacity: 0;
}

.icon {
  width: 2.5rem;
  height: 2.5rem;
  fill: white;
  transition: all 0.2s ease;

  &:hover {
    cursor: help;
    fill: tomato;
  }
}

@keyframes fadeInFromNone {
  0% {
    display: block;
    opacity: 0;
  }

  100% {
    display: block;
    opacity: 1;
  }
}
