.hamburgerContainer {
  position: fixed;
  top: 2rem;
  right: 2rem;
  background-color: inherit;
  z-index: 9999;

  &:hover {
    cursor: pointer;
  }

  @media screen and (min-width: 1200px) {
    right: 3rem;
    top: 3rem;
  }
}

.menuToggle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 4rem;
  height: 4rem;

  span {
    display: block;
    width: 3.3rem;
    height: 4px;
    margin-bottom: 0.5rem;
    position: relative;
    background: white;
    border-radius: 3px;
    z-index: 1000;
    transform-origin: 4px 0px;
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
      background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;

    &.black {
      background: black;
    }

    &.first {
      opacity: 1;
      transform: rotate(45deg) translate(-2px, -1px);
      background: #232323;

      @media screen and (min-width: 1200px) {
        transform: rotate(45deg) translate(0, 0);
      }
    }

    &.second {
      opacity: 0;
      transform: rotate(0deg) scale(0.2, 0.2);
    }

    &.third {
      transform: rotate(-45deg) translate(0, -4px);
      background: #232323;

      @media screen and (min-width: 1200px) {
        transform: rotate(-45deg) translate(-3px, 0);
      }
    }
  }
}
