.contactContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.contactWrapper {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  padding: 2rem;

  @media screen and (min-width: 800px) {
    max-width: 90%;
    height: 40vw;
    min-height: 40rem;
    max-height: 800px;
    padding: 0;
  }

  @media screen and (min-width: 1400px) {
    max-width: 1200px;
  }

  .contact {
    font-size: 1.4rem;
    line-height: 1.7rem;
    letter-spacing: 0.1rem;
    text-transform: uppercase;
    color: #c4c4c4;
    padding: 3rem 0;
  }

  .contactText {
    font-size: 3.1rem;
    line-height: 3.6rem;
    padding-bottom: 2.5rem;
    color: black;
  }

  .contactLinkText {
    display: flex;
    padding: 2rem 0;
    align-items: center;

    a:last-child {
      font-size: 1.8rem;
      margin-left: 2rem;
      text-decoration: none;
      color: black;
      transition: all 0.2s ease;

      &:hover {
        cursor: pointer;
        color: tomato;
      }

      @media screen and (max-width: 360px) {
        font-size: 1.4rem;
      }
    }
  }
}
