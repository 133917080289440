.projectContainer {
  position: relative;

  @media screen and (min-width: 768px) {
    width: 90%;
    display: grid;
    grid-template-columns: 60% 40%;
    border-radius: 0 0.2rem 0.2rem 0;
    overflow: hidden;
    padding-bottom: 9rem;

    .projectInfo {
      grid-column: 2 / 2;
      grid-row: 1 / span 2;
    }

    .imageContainer {
      grid-column: 1 / 1;
      grid-row: 1 / span 2;
    }
  }

  @media screen and (min-width: 1040px) {
    .projectTech {
      display: unset;
      position: absolute;
      top: 0;
      right: 0;
      margin: 1rem;
      font-size: 1.6rem;
      transform: rotate(90deg) translatex(50%) translateY(-40%);
    }
  }
}

.projectContainerRight {
  position: relative;

  @media screen and (min-width: 768px) {
    width: 90%;
    margin-left: 10%;
    display: grid;
    grid-template-columns: 40% 60%;
    margin-bottom: 9rem;
    border-radius: 0.2rem 0 0 0.2rem;
    overflow: hidden;

    &:last-child {
      margin-bottom: 0;
      padding-bottom: 9rem;
    }

    .imageContainer {
      grid-column: 2 / 2;
      grid-row: 1 / span 2;
    }

    .projectInfo {
      grid-column: 1 / 1;
      grid-row: 1 / span 2;
      text-align: right;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
  }

  @media screen and (min-width: 1040px) {
    .projectTech {
      display: unset;
      position: absolute;
      top: 0;
      left: 0;
      margin: 1rem;
      font-size: 1.6rem;
      transform: rotate(-90deg) translatex(-50%) translateY(-40%);
    }
  }
}

.projectTech {
  display: none;
}

.imageContainer {
  width: 100%;
  height: 40rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  overflow: hidden;

  picture {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  img {
    max-height: 95%;
    max-width: 95%;
  }

  @media screen and (min-width: 768px) {
    min-height: 40vw;
    max-height: 600px;
  }

  @media screen and (min-width: 900px) {
    min-height: unset;
    height: 40vw;
    max-height: 600px;
  }
}

.projectInfo {
  padding: 4rem 2rem 6rem 2rem;

  @media screen and (min-width: 768px) {
    background-color: rgb(27, 27, 27);
    padding: 0 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  @media screen and (min-width: 1040px) {
    justify-content: flex-end;
  }

  @media screen and (min-width: 1100px) {
    padding-bottom: 5%;
  }

  @media screen and (min-width: 1400px) {
    padding-bottom: 10%;
  }

  .projectName {
    font-size: 2.8rem;
    padding: 1rem 0 3rem 0;
    display: flex;

    @media screen and (min-width: 768px) and (max-width: 800px) {
      font-size: 2.4rem;
      padding: 1rem 0 2rem 0;
    }

    @media screen and (min-width: 1040px) {
      font-size: 3.1rem;
    }

    .projectGithub {
      margin-left: 2rem;
      display: flex;
      align-items: center;

      a {
        display: flex;
        align-items: center;

        .githubIcon {
          width: 2.5rem;
          height: 2.5rem;
          transition: all 0.2s ease;

          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }

  .projectText {
    font-size: 2rem;
    font-weight: 300;
    line-height: 2.5rem;
    padding-bottom: 3rem;

    @media screen and (min-width: 768px) {
      font-size: 1.6rem;
      padding-bottom: 2rem;
    }

    @media screen and (min-width: 800px) {
      padding-bottom: 3rem;
    }

    @media screen and (min-width: 1200px) {
      font-size: 1.5rem;
    }

    @media screen and (min-width: 1400px) {
      font-size: 1.7rem;
    }
  }

  .projectLink {
    font-size: 2rem;
    font-weight: 300;
    margin-bottom: 3rem;
    text-decoration: none;
    transition: all 0.2s ease;

    @media screen and (min-width: 768px) {
      font-size: 1.6rem;
      margin-bottom: 2rem;
    }

    @media screen and (min-width: 800px) {
      font-size: 1.7rem;
    }

    @media screen and (min-width: 850px) {
      font-size: 1.8rem;
    }

    @media screen and (min-width: 910px) {
      font-size: 1.9rem;
    }
  }
}
