.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;
  padding: 2rem;

  @media screen and (min-width: 1200px) {
    padding: 2rem 3rem;
  }

  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 100;
}
